import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useDispatch, useSelector } from "src/store";
import {
  getNotifications,
  clearNotifications,
  setPopoverOpen
} from "src/slices/notification";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { Alert02Icon, CancelCircleIcon, InformationCircleIcon, Notification01Icon, Notification02Icon } from "@hugeicons/react-pro";

const useStyles = makeStyles(theme => ({
  popover: {
    width: 420
  },
  notification: {
    textTransform: "none",
    borderRadius: 0
  },
  icon: {
    color: theme.palette.text.primary
  },
  infoIcon: {
    backgroundColor: theme.palette.info.main
  },
  warningIcon: {
    backgroundColor: theme.palette.warning.main
  },
  errorIcon: {
    backgroundColor: theme.palette.error.main
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const history = useHistory();
  const { notifications, isPopoverOpen } = useSelector(
    state => state.notifications
  );
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const iconsMap = {
    info: {
      image: InformationCircleIcon,
      class: classes.infoIcon
    },
    warning: {
      image: Alert02Icon,
      class: classes.warningIcon
    },
    error: {
      image: CancelCircleIcon,
      class: classes.errorIcon
    }
  };

  const handleOpen = () => {
    dispatch(setPopoverOpen(true));
  };

  const handleClose = () => {
    dispatch(setPopoverOpen(false));
  };

  useEffect(() => {
    dispatch(getNotifications()).catch(() => {
      if (isMountedRef.current)
        enqueueSnackbar("There was an error getting notifications");
    });
  }, [dispatch, enqueueSnackbar, isMountedRef]);

  const handleNotificationClicked = notification => () => {
    dispatch(clearNotifications([notification.id]));

    if (isMountedRef.current) {
      history.push({
        pathname: notification.href
      });
    }
  };

  const handleNotificationsCleared = () => {
    let notificationIds = [];
    notifications.forEach(notification =>
      notificationIds.push(notification.id)
    );
    dispatch(clearNotifications(notificationIds));
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <Badge
            badgeContent={notifications.length}
            color="error"
            variant="standard"
            invisible={!notifications.length}
          >
            <SvgIcon>
              <Notification01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isPopoverOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map(notification => {
                const icon = iconsMap[notification.type.toLowerCase()];
                const Image = icon.image;

                return (
                  <ListItem
                    className={classes.notification}
                    component={Button}
                    divider
                    key={notification.id}
                    onClick={handleNotificationClicked(notification)}
                  >
                    <ListItemAvatar>
                      <Avatar className={clsx(classes.icon, icon.class)}>
                        <SvgIcon fontSize="small">
                          <Image />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{
                        variant: "subtitle2",
                        color: "textPrimary"
                      }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box p={1} display="flex" justifyContent="center">
              <Button size="small" onClick={handleNotificationsCleared}>
                Clear All
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
